
import Vue from 'vue';

export default Vue.extend({
    name: 'history',
    components: {},
    data() {
        return {
            params:{ server:'1'},
            events: [],
        }
    },
    created() {
        this.postUserInfo();
    },
    methods: {
        goPage(event) {
            this.$router.replace({path: '/history/list', query: {id: event.id}});
        },
        goBack(){
            this.$router.replace({path:'/home'});
        },
        async postUserInfo() {
            try {
                const result = await this.$api.postYear(this.params);
                this.events = result.data.list
            } catch (error) {
                console.log('error', error)
            }
        }
    }
});
